// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  padding: 50px 0;
  background-color: #c6d1d3;
}

.contact-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}
.carrerImage {
  align-items: center;
  border-radius: 25px;
  margin-top: 2em;
}
@media (max-width: 768px) {
  .contact-section h2 {
    font-size: 2rem;
  }

  .contact-section p {
    font-size: 1rem;
  }
}
.contactTitle {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/CONTACT/contact.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".contact-section {\n  padding: 50px 0;\n  background-color: #c6d1d3;\n}\n\n.contact-section h2 {\n  font-size: 2.5rem;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 20px;\n}\n\n.contact-section p {\n  font-size: 1rem;\n  color: #555;\n  line-height: 1.6;\n  margin-bottom: 20px;\n}\n.carrerImage {\n  align-items: center;\n  border-radius: 25px;\n  margin-top: 2em;\n}\n@media (max-width: 768px) {\n  .contact-section h2 {\n    font-size: 2rem;\n  }\n\n  .contact-section p {\n    font-size: 1rem;\n  }\n}\n.contactTitle {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
