import { Col, Container, Row } from "react-bootstrap";
import "./index.css";

const Footer = () => {
  // return (
  //   <Container fluid className="footer">
  //     <Row>
  //       <Col xs={12} md={6} className="footer-col">
  //         <div className="logoContainer">
  //           <img
  //             alt="Urgent Care Clinical Trials - a KUR International Company"
  //             src={ucctLogoWhite}
  //             className="footerLogo"
  //           />
  //           <p className="footerText">
  //             Urgent Care Clinical Trials is a clinical research Site Management
  //             Organization (SMO) developing the first national Investigative
  //             Site Network (ISN) specifically geared to the urgent care
  //             industry.
  //           </p>
  //         </div>
  //       </Col>
  //       <Col xs={12} md={3} className="footer-col footerEnd">
  //         <div>
  //           <strong>UCC Trials</strong>
  //           <ul className="footer-list footerText">
  //             <a href="/job-application">
  //               <li>Job Application</li>
  //             </a>
  //             <a href="/mission-statement">
  //               <li>Mission Statement</li>
  //             </a>
  //             <a href="/responsibilities">
  //               <li>Responsibilities</li>
  //             </a>
  //           </ul>
  //         </div>
  //       </Col>
  //       <Col xs={12} md={3} className="footer-col footerEnd">
  //         <div>
  //           <strong>Services</strong>
  //           <ul className="footer-list footerText">
  //             <a href="/assessment">
  //               <li>Assessment</li>
  //             </a>
  //             <a href="/implementation">
  //               <li>Implementation</li>
  //             </a>
  //             <a href="/management">
  //               <li>Management</li>
  //             </a>
  //           </ul>
  //         </div>
  //       </Col>
  //     </Row>
  //     <Row className="bottom-footer ">
  //       <Col className="footerText">Copyright © 2023 UCCTrials</Col>
  //     </Row>
  //   </Container>
  // );
};

export default Footer;
