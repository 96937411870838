import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./index.css";

const NotFoundPage = () => {
  return (
    <div className="vertical-center">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <h1>😮</h1>
            <h2>Oops! Page Not Found</h2>
            <p>Sorry but the page you are looking for does not exist.</p>
            <Button variant="secondary" href="/">
              Back to homepage
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFoundPage;
