import React from "react";
import { Container, Row } from "react-bootstrap";
import {
  faHandshake,
  faScaleBalanced,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ExpandableCards from "../../../../COMPONENTS/COMMON/CARD/EXPANDABLE_CARDS";
import "./coreValues.css";

const stepsData = [
  {
    id: 1,
    title: "Excellence",
    icon: faStar,
    content:
      "Committing to the highest standards of quality and efficiency in all services, from biospecimen collection to clinical trial management ",
    background: "https://via.placeholder.com/400x300",
  },
  {
    id: 2,
    title: "Collaboration",
    icon: faHandshake,
    content:
      "Fostering strong partnerships with clients, stakeholders, and global teams to achieve common goals and drive innovation.",
    background: "https://via.placeholder.com/400x300", // Add a background image
  },
  {
    id: 3,
    title: "Integrity",
    icon: faScaleBalanced,
    content:
      "Upholding the highest ethical standards in all operations, ensuring transparency and trustworthiness in every action.",
    background: "https://via.placeholder.com/400x300", // Add a background image
  },
];

const CoreValues = () => {
  return (
    <Container fluid className="values" id="about-us">
      <Row className="h-100 align-items-center">
        <ExpandableCards cardData={stepsData} />
      </Row>
    </Container>
  );
};

export default CoreValues;
