import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./careers.css";

const Careers = () => {
  return (
    <Container fluid id="careers" className="careers-section">
      <Row className="justify-content-center">
        <Col md={6} className="text-center">
          <h2>Join Our Team at Atlas Essentials India</h2>
          <p>
            At Atlas Essentials, we foster a collaborative and innovative work
            environment that empowers professionals to grow and make a
            meaningful impact. We are always seeking talented professionals who
            are committed to making a positive impact. Whether you specialize in
            finance, quality assurance, clinical operations, data management, or
            human resources, Atlas Essentials offers opportunities to grow your
            career while contributing to meaningful projects that shape the
            future of healthcare. Join us and be part of a team where your
            skills, ideas, and dedication can make a difference.
          </p>
        </Col>
        <Col md={6} className="text-center">
          <img
            className="carrerImage"
            src="https://images.pexels.com/photos/955395/pexels-photo-955395.jpeg?auto=compress&cs=tinysrgb&w=600"
          ></img>
        </Col>
      </Row>
    </Container>
  );
};

export default Careers;
