import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./contact.css";
import ContactForm from "../../../../COMPONENTS/FORM/CONTACT";

const Contact = () => {
  return (
    <Container fluid id="contact" className="contact-section">
      <h2 className="contactTitle">Contact Us</h2>
      <ContactForm />
    </Container>
  );
};

export default Contact;
