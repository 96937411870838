// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#about-us {
  background-color: #c6d1d3 !important;
  /* background-color: #b7c3c6 !important; */
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/CORE_VALUES/coreValues.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0CAA0C;AAC5C","sourcesContent":["#about-us {\n  background-color: #c6d1d3 !important;\n  /* background-color: #b7c3c6 !important; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
