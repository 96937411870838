import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import atlasLogo from "./../../../../ASSETS/AtlasLogo.png";
const HeroSection = () => {
  return (
    <Container fluid className="hero-section" id="home">
      <Row className="heroContent">
        <Col xs={12} className="video-container">
          <video
            src="https://videos.pexels.com/video-files/853789/853789-hd_1920_1080_25fps.mp4"
            alt="Hero Banner"
            className="hero-image"
            autoPlay
            loop
            muted
          />
          <div className="hero-overlay"></div>
        </Col>

        <Col xs={12} className="hero-text-container">
          <div className="hero-text">
            <img src={atlasLogo} className="hero-atlas-brand-logo" />
            <h1 className="heroTitle">ATLAS ESSENTIALS INDIA</h1>
            <h2 className="heroSubTitle">Making progress possible…</h2>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
