// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #212849;
  color: #fff;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 30em;
}

.footerLogo {
  width: 60% !important;
  margin-bottom: 1em;
}

.footer-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.footer li {
  margin: 0.5em 0;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  line-height: 2em;
}

.footerEnd {
  flex: 1 1;
}

.bottom-footer {
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  padding: 1em;
  margin: 0 1em;
  text-align: left;
  font-size: 12px;
}

.footerText {
  color: rgba(255, 255, 255, 0.75);
  width: 20em;
}

.footer a {
  text-decoration: none;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/FOOTER/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,8CAA8C;EAC9C,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".footer {\n  background-color: #212849;\n  color: #fff;\n}\n\n.logoContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: left;\n  width: 30em;\n}\n\n.footerLogo {\n  width: 60% !important;\n  margin-bottom: 1em;\n}\n\n.footer-list {\n  list-style-type: none;\n  padding-left: 0;\n  margin: 0;\n}\n\n.footer li {\n  margin: 0.5em 0;\n}\n\n.footer-col {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: 2em;\n  margin-bottom: 2em;\n  line-height: 2em;\n}\n\n.footerEnd {\n  flex: 1;\n}\n\n.bottom-footer {\n  border-top: 2px solid rgba(255, 255, 255, 0.1);\n  padding: 1em;\n  margin: 0 1em;\n  text-align: left;\n  font-size: 12px;\n}\n\n.footerText {\n  color: rgba(255, 255, 255, 0.75);\n  width: 20em;\n}\n\n.footer a {\n  text-decoration: none;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
