import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

import NotFoundPage from "./PAGES/404";
import HomePage from "./PAGES/HOME";

function App() {
  return (
    <div className="app-container" style={{ width: "100%" }}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
