// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.careers-section {
  padding: 50px;
  background-color: #c6d1d3;
}

.careers-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1em;
  margin-top: 1em;
}

.careers-section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}
.carrerImage {
  align-items: center;
  border-radius: 25px;
  margin-top: 2em;
}
@media (max-width: 768px) {
  .careers-section h2 {
    font-size: 2rem;
  }

  .careers-section p {
    font-size: 1rem;
  }
  .carrerImage {
    align-items: center;
    border-radius: 25px;
    margin-top: 2em;
    height: 250px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/CAREERS/careers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,aAAa;EACf;AACF","sourcesContent":[".careers-section {\n  padding: 50px;\n  background-color: #c6d1d3;\n}\n\n.careers-section h2 {\n  font-size: 2.5rem;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 1em;\n  margin-top: 1em;\n}\n\n.careers-section p {\n  font-size: 1rem;\n  color: #555;\n  line-height: 1.6;\n  margin-bottom: 20px;\n  text-align: center;\n}\n.carrerImage {\n  align-items: center;\n  border-radius: 25px;\n  margin-top: 2em;\n}\n@media (max-width: 768px) {\n  .careers-section h2 {\n    font-size: 2rem;\n  }\n\n  .careers-section p {\n    font-size: 1rem;\n  }\n  .carrerImage {\n    align-items: center;\n    border-radius: 25px;\n    margin-top: 2em;\n    height: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
