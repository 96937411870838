import React from "react";
import styles from "./brief.module.css";

const AtlasBrief = () => {
  return (
    <div className={styles.briefSection}>
      <p>
        Atlas Essentials India is a dedicated support system for providing
        comprehensive administrative assistance and integrated infrastructure to
        KUR International’s global teams. Our mission is to streamline
        operations and enhance efficiency across KUR's diverse initiatives in
        clinical research, diagnostics, and patient care. With expertise in
        clinical operations, finance, quality assurance, data management, and
        human resources, we ensure seamless execution of vital functions,
        enabling KUR’s teams to focus on advancing science, research, and
        improving patient outcomes. At Atlas Essentials, we are committed to
        driving superior healthcare results and supporting KUR’s mission to
        enhance clinical research and patient care worldwide.
      </p>
    </div>
  );
};

export default AtlasBrief;
