import axios from "axios";
// import { BE_DOMAIN, BE_VERSION } from "../../constants";
// import { useContext } from "react";
// import { AuthContext } from "../../authProvider";

// const axiosGet = async (url, token) => {
//   try {
//     const response = await axios.get(`${BE_DOMAIN}/${BE_VERSION}/${url}`, {
//       headers: {
//         Authorization: `Bearer ${token}`, // Set the Authorization header
//       },
//     });
//     return response.data.responsePayload;
//   } catch (error) {
//     return handleAxiosError(error);
//   }
// };

const axiosPost = async (url, payload, token) => {
  try {
    const response = await axios.post(`${url}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header
      },
    });
    return {
      statusCode: response.status,
      responsePayload: response.data.responsePayload,
    };
  } catch (error) {
    return handleAxiosError(error);
  }
};

// const axiosSignInPost = async (url, payload) => {
//   try {
//     const response = await axios.post(
//       `${BE_DOMAIN}/${BE_VERSION}/${url}`,
//       payload
//     );
//     return {
//       statusCode: response.status,
//       responsePayload: response.data.responsePayload,
//     };
//   } catch (error) {
//     return handleAxiosError(error);
//   }
// };

// const axiosPatch = async (url, payload, token) => {
//   try {
//     const response = await axios.patch(
//       `${BE_DOMAIN}/${BE_VERSION}/${url}`,
//       payload,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`, // Set the Authorization header
//         },
//       }
//     );
//     return {
//       statusCode: response.status,
//       responsePayload: response.data.responsePayload,
//     };
//   } catch (error) {
//     return handleAxiosError(error);
//   }
// };

const handleAxiosError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    return {
      statusCode: error.response.status,
      responsePayload:
        error.response.data.responsePayload || "An error occurred",
    };
  } else if (error.request) {
    // The request was made but no response was received (network error)
    return {
      statusCode: 500, // You can define a custom status code for network errors
      responsePayload: "Network error: Please check your connection",
    };
  } else {
    // Something happened in setting up the request that triggered an error
    return {
      statusCode: 500,
      responsePayload: "An unexpected error occurred",
    };
  }
};

export {
  axiosPost,
  // axiosGet, axiosPatch, axiosSignInPost
};
