import React, { useState, useEffect } from "react";
import { Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./expandable.module.css";

const ExpandableCards = ({ cardData }) => {
  const [expandedCard, setExpandedCard] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (cardIndex) => {
    setExpandedCard(expandedCard === cardIndex ? null : cardIndex);
  };

  return (
    <div className={styles.cardsContainer}>
      {cardData.map(({ id, title, content, icon }) => (
        <Col xs={12} sm={4} md={3} key={id} className={styles.cardWrapper}>
          <Card
            className={`${styles.customCard} ${isLoaded ? styles.loaded : ""} ${
              expandedCard === id ? styles.zoomed : ""
            }`}
            onClick={() => handleCardClick(id)}
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon icon={icon} className={styles.cardIcon} />
            </div>
            <Card.Body>
              <Card.Title className={styles.cardTitle}>{title}</Card.Title>
              <Card.Text className={styles.cardText}>{content}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </div>
  );
};

export default ExpandableCards;
